.workforce .heading, .workforce-settings .heading {
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr auto !important; } }
.workforce-details {
  .layout {
    grid-template-columns: auto repeat(6, 1fr);
    @media screen and (max-width: 1024px) {
      grid-template-columns: auto repeat(3, 1fr); }
    @media screen and (max-width: 768px) {
      grid-template-columns: auto repeat(2, 1fr); }
    .image {
      grid-row: 1/4;
      @media screen and (max-width: 1024px) {
        grid-row: 1/7; }
      @media screen and (max-width: 768px) {
        grid-row: 1/10; }
      div {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        overflow: hidden; }
      img {
        width: auto !important;
        height: 100% !important;
        top: 50% !important;
        left: 50% !important;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%); } } }

  .detail {
    overflow: hidden;
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: scale(0.75); }
    /* -----------------------Custom layout for details----------------------- */
    &:nth-child(2), &:nth-child(11), &:nth-child(14), &:nth-child(17) {
      grid-column: 2/4; }
    &:nth-child(3), &:nth-child(12), &:nth-child(15), &:nth-child(18) {
      grid-column: 4/6; }
    &:nth-child(10), &:nth-child(13), &:nth-child(16) {
      grid-column: 6/8; }
    &:nth-child(6) {
      grid-column: 2; }
    &:nth-child(7) {
      grid-column: 3; }
    &:nth-child(8) {
      grid-column: 4; }
    &:nth-child(9) {
      grid-column: 5; }
    &:nth-child(4) {
      grid-column: 6; }
    &:nth-child(5) {
      grid-column: 7; }
    @media screen and (max-width: 1024px) {
      &:nth-child(2), &:nth-child(5), &:nth-child(8), &:nth-child(11), &:nth-child(14), &:nth-child(17) {
        grid-column: 2; }
      &:nth-child(3), &:nth-child(6), &:nth-child(9), &:nth-child(12), &:nth-child(15), &:nth-child(18) {
        grid-column: 3; }
      &:nth-child(4), &:nth-child(7), &:nth-child(10), &:nth-child(13), &:nth-child(16) {
        grid-column: 4; } }
    @media screen and (max-width: 768px) {
      &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8), &:nth-child(11), &:nth-child(13), &:nth-child(15), &:nth-child(17) {
        grid-column: 2; }
      &:nth-child(10) {
        grid-column: 2/3; }
      &:nth-child(3), &:nth-child(5), &:nth-child(7), &:nth-child(9), &:nth-child(12), &:nth-child(14), &:nth-child(16), &:nth-child(18) {
        grid-column: 3; } } }


  label {
    text-transform: uppercase;
    font-weight: 900;
    color: black;
    white-space: nowrap; } }

.workforce-settings {
  .table {
    &.contacts, &.job-manager {
      padding: 0px 40px; } } }
