.filter-btn .MuiSvgIcon-colorPrimary {
  color: white; }

.nav-filters {
  width: 220px;
  padding-top: 10px;
  .title {
    grid-template-columns: 1fr auto;
    align-items: center;
    h1 {
      font-size: 15px; }
    button {
      padding: 5px !important; } }
  .MuiInput-formControl {
    border-radius: 20px;
    border: 1px solid #1884F0;
    padding: 5px !important;
    &:before, &:after {
      display: none; } }
  .separator {
    height: 1px; }
  .MuiPopover-paper {
    border: 1px solid #1884F0; }
  #filter {
    border: none; }

  .filter {
    .MuiTextField-root {
      padding-bottom: 20px; }
    .list {
      padding: 0; } }

  .sort {
    h1 {
      grid-column: 1/3; } }

  .sort, .project-status {
    h1 {
      font-size: 15px; } } }
