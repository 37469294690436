.custom-nav-icon {
  border: 1px solid white;
  height: 35px;
  width: 35px;
  display: grid;
  align-items: center;
  justify-items: center;
  border-radius: 50%;
  p {
    color: white;
    font-size: 10px; } }

.custom-section-icon {
  border: 1px solid white;
  background-color: #DFDED9;
  height: 28px;
  width: 28px;
  display: grid;
  align-items: center;
  justify-items: center;
  border-radius: 50%;
  &.active {
    background-color: #1884F0;
    border-color: #1884F0;
    p {
      color: white; } }
  p {
    color: black;
    font-weight: 900;
    font-size: 16px; } }
