.text-field-button {
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: end; }
.custom-input-datepicker {
  button.MuiIconButton-root {
    padding: 1px !important; }
  input.MuiInputBase-input {
    border: none !important;
    padding: 0px !important; }
  .MuiInputBase-root {
    border-radius: 5px;
    border: 1px solid black;
    padding: 0px;
    font-size: 13px; }
  .MuiInputAdornment-positionStart {
    margin-right: 2px; } }

.filter-dropwdown {
  grid-template-columns: auto auto;
  .date-picker {}
  grid-template-columns: auto auto; }

.MuiTextField-root {
  align-items: start; }

.Mui-error {
  input {
    border-color: #f44336 !important; } }

.MuiInputLabel-formControl {
  white-space: nowrap;
  width: 100%;
  width: -webkit-fill-available; }

/* -----------------------Other Charges----------------------- */
#charges-popover {
  .MuiPopover-paper {}
  border-radius: 0;
  .card {
    padding: 0px;
    .title {
      padding: 5px 15px;
      color: white;
      background-color: #1884F0; }
    .charges {
      padding: 0px 15px 15px;
      .headings {
        grid-template-columns: 300px 170px; }
      .charge {
        grid-template-columns: 300px 100px 60px;
        align-items: center;
        input.Mui-disabled {
          color: black; }
        .charge-btns {
          display: grid;
          grid-template-columns: auto auto; } }
      .total {
        grid-template-columns: auto auto 170px;
        align-items: center; } } } }
