body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  display: grid;
  padding: 20px 0px;
  justify-content: center;
  grid-template-columns: 97%;
  position: relative; }

/* ----------------------------Pallete---------------------------- */
.pc1 {
  color: #1884F0
}

/* ----------------------------Typography---------------------------- */
.heading {
  padding: 0px 12px;
  position: relative;
}

.heading.underline::after {
  content: '';
  background-color: #1884F0;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -11px;
  left: 0px;
}

h1, h2, h3, h4, h5, h6, p, a, li {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  color: #3D3D3D;
}

h1 {
  position: relative;
  font-size: 18px;
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 14px;
}

h4 {
  font-size: 13px;
}

h5 {
  font-size: 12px;
}

ul {
  padding: 0px;
  margin: 0px;
} 

ul li {
  display: grid;
  font-size: 12px;
  margin-left: 0px;
}

p {
  font-size: 15px;
}

.uppercase {
  text-transform: uppercase;
}

.center {
  text-align: center;
}

/* ----------------------------Grid Layout---------------------------- */
.jcenter {
  justify-self: center; }

.acenter {
  align-self: center; }

.jstart {
  justify-self: start; }

.astart {
  align-self: start; }

.jend {
  justify-self: end; }

.aend {
  align-self: end; }

.one, .two, .three, .four, .five, .six, .seven, .auto {
  display: grid; }

.one {
  grid-template-columns: 1fr; }

.two {
  grid-template-columns: repeat(2, 1fr); }
  @media screen and (max-width: 768px) {
    .two {
      grid-template-columns: 1fr; } }

.three {
  grid-template-columns: repeat(3, 1fr); }
  @media screen and (max-width: 768px) {
    .three {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (max-width: 480px) {
    .three {
      grid-template-columns: 1fr; } }

.four {
  grid-template-columns: repeat(4, 1fr); }

.five {
  grid-template-columns: repeat(5, 1fr); }

.six {
  grid-template-columns: repeat(6, 1fr); }

.seven {
  grid-template-columns: repeat(7, 1fr); }

.eight {
  grid-template-columns: repeat(8, 1fr); }

.auto {
  grid-template-columns: auto; }

.gcg5 {
  grid-column-gap: 5px; }

.gcg10 {
  grid-column-gap: 10px; }

.gcg20 {
  grid-column-gap: 20px; }

.gcg30 {
  grid-column-gap: 30px; }

.gcg40 {
  grid-column-gap: 40px; }

.grg5 {
  grid-row-gap: 5px; }

.grg10 {
  grid-row-gap: 10px; }

.grg20 {
  grid-row-gap: 20px; }

.grg30 {
  grid-row-gap: 30px; }

.grg40 {
  grid-row-gap: 40px; }

.gcrg5 {
  grid-column-gap: 5px;
  grid-row-gap: 5px; }

.gcrg10 {
  grid-column-gap: 10px;
  grid-row-gap: 10px; }

.gcrg20 {
  grid-column-gap: 20px;
  grid-row-gap: 20px; }

.gcrg30 {
  grid-column-gap: 30px;
  grid-row-gap: 30px; }

.gcrg40 {
  grid-column-gap: 40px;
  grid-row-gap: 40px; }

.col1-span {
  grid-column: 1; }

.col2-span {
  grid-column: 1/3; }
  @media screen and (max-width: 768px) {
    .col2-span {
      grid-column: 1; } }

.col3-span {
  grid-column: 1/4; }
  @media screen and (max-width: 768px) {
    .col3-span {
      grid-column: 1; } }

.col4-span {
  grid-column: 1/5; }
  @media screen and (max-width: 768px) {
    .col4-span {
      grid-column: 1; } }

.col5-span {
  grid-column: 1/6; }
  @media screen and (max-width: 768px) {
    .col5-span {
      grid-column: 1; } }

.col6-span {
  grid-column: 1/7; }
  @media screen and (max-width: 768px) {
    .col6-span {
      grid-column: 1; } }

/* ----------------------------User---------------------------- */
.user {
  display: grid;
  padding: 0;
  min-height: 100vh;
  background-color: lightgrey;
  grid-template-columns: 350px;
  justify-content: center;
  align-content: center;
}

.user.registration input,
.user.forgot-password input,
.user.login input {
  border: none;
}

.user .links {
  justify-items: center;
}

.user h1 {
  font-size: 22px;
  padding-left: 0px;
}

.user h1::after {
  display: none;
}

/* ----------------------------User Card---------------------------- */
.user .card {
  padding: 0px;
  border-radius: 40px;
}

.user .card .header {
  background-color: #001C38;
}

.user .card .header .logo {
  display: grid;
  padding: 8px 40px;
}

.user .card .header .logo img {
  width: 100%;
}

.user .card .body{
  grid-template-columns: 100%;
  justify-content: center;
  display: grid;
  grid-row-gap: 40px;
}

.user .card .body button{
  width: 190px;
  justify-self: center;
}

/* ----------------------------Global Card---------------------------- */
.card {
  display: grid;
  background: white;
  padding: 15px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.25); 
  box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.25);
}

.card .body {
  display: grid;
  padding: 40px;
}

.separator {
  background-color: #D3D3D3;
  width: 100%;
  height: 2px;
}

/* -----------------------Dynamic Table----------------------- */
.settings-table {
  width: 50% !important;
}
@media screen and (max-width: 1024px) {
  .settings-table {
    width: 100% !important;
  }

  /* .table .thead tr th {
    white-space: nowrap;
  } */
}

.table .thead tr th {
  padding: 10px 7px;
  font-weight: 900;
  line-height: 1rem;
  font-size: 14px;
  background-color: #fff;
}

.table .tbody tr td, 
.table .tfoot tr td {
  padding: 7px 7px;
  font-size: 12px;
}

.sticky {
  top: 0;
  left: 0;
  z-index: 2;
  position: sticky;
}

.table .dropdown-btn, 
.delete-btn, 
.copy-btn, 
.edit-btn {
  padding: 7px 7px !important;
  color: #8AC0FF !important;
}

.table .dropdown-btn .MuiSvgIcon-root {
  background-color: #676767;
  color: white;
  border-radius: 20px;
}

/* -----------------------Loader----------------------- */
.loader {
  display: grid;
  grid-row-gap: 20px;
  padding: 60px 0;
  width: 100%;
  /* height: 100vh; */
  justify-content: center;
  align-content: center;
}

.container {
  margin-left: 73px;
}

/* -----------------------Popper----------------------- */
.popper {
  z-index: 999;
}

.popper .card {
  border-radius: 20px;
}

.popper .card.custom-padding {
  padding: 10px 0px;
}

.popper .card .btn-link {
  cursor: pointer;
  padding: 5px 15px;
}

.popper .card .btn-link:hover {
  background-color: #1884F0;
  color: #fff;
}

/* -----------------------Scrollbar----------------------- */
*::-webkit-scrollbar:vertical {
  width: 10px;
}
*::-webkit-scrollbar-track:vertical {
  background: #001c38;
}
*::-webkit-scrollbar-thumb:vertical {
  background-color: #d3d3d3;
  border-radius: 20px;
  border: 3px solid #001c38;
}
*::-webkit-scrollbar {
  height: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 20px;
  border: 3px solid transparent;
}

table::-webkit-scrollbar{
  width: auto;
}
table::-webkit-scrollbar-thumb:horizontal{
  background: transparent;
  background-color: #d3d3d3;
  border-radius: 20px;
  border: 3px solid transparent;
}

/* -----------------------Other----------------------- */
.btn.small {
  height: 25px;
}

.click {
  cursor: pointer;
}

.correct {
  background-color: #8AC480;
  border-radius: 50%;
  color: white;
}

.in-correct {
  background-color: #C06461;
  border-radius: 50%;
  color: white;
}

.Blacklisted td {
  color:#F93324;
  font-weight: 900; 
  cursor: pointer;
}

/* .Permanent td {
  color: limegreen;
  font-weight: 900; 
} */

/* .leftIcon.active, */
.custom-nav-icon.active {
  background-color: #1884F0;
}
