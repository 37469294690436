.upload.disabled .button {
  background-color: #BDBDBD;
  cursor: default;
}

.upload.disabled .button:hover {
  background-color: #BDBDBD;
}

.upload .button {
  padding: 8px 20px;
  background-color: #1884F0;
  border-radius: 20px;
}

.upload .button:hover {
  background-color: #105CA8;
}

.upload .button h1 {
  color: #fff
}