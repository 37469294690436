.notify-btn .MuiSvgIcon-colorPrimary {
    color: white; }

.notifications {
  width: 220px;
  padding-top: 10px;
  .title {
    grid-template-columns: 1fr auto;
    align-items: center;
    h1 {
      font-size: 15px; }
    button {
      padding: 5px !important; } }
  .notification {
    .date {
      background-color: #BCDDF6;
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 15px; }
    .item {
      padding: 0px 10px;
      position: relative;
      &::after {
        content: '';
        background-color: #D3D3D3;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: -5px;
        left: 0; }
      &:last-child::after {
        display: none; }
      &.unread {
        p {
          font-weight: 900; } }
      p {
        font-size: 14px; }
      .time {
        color: #ababab; } } } }
