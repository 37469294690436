/* ----------------------------User Card---------------------------- */
.payments {
  width: 100%;
  height: 100vh;
}

.payments .card {
  padding: 0px;
  border-radius: 40px;
  width: 350px;
  justify-self: center;
  align-self: center;
}

.payments .card .header {
  background-color: #001C38;
}

.payments .card .header .logo {
  display: grid;
  padding: 8px 40px;
}

.payments .card .header .logo img {
  width: 100%;
}

.payments .card .body{
  grid-template-columns: 100%;
  justify-content: center;
  display: grid;
  grid-row-gap: 25px;
}

.payments .card .body button{
  width: 190px;
  justify-self: center;
}