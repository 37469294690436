/* .MuiInputBase-input {
  padding: 2px !important;
} */

.MuiTableHead-root {
  text-transform: uppercase;
}

.applied-filter h1 {
  font-size: 14px;
  padding-left: 10px;
  font-weight: 900;
}

.applied-filter ul {
  grid-template-columns: repeat(4, auto);
}

.applied-filter ul li {
  font-weight: 500;
  padding: 10px 20px 10px 0px;
  border-right: 1px solid #D3D3D3;
  grid-template-columns: auto auto;
  grid-column-gap: 5px;
}

.applied-filter ul li:last-child {
  padding-right: 0px;
  border-right: none;
}

@media screen and (max-width: 1280px) {
  .applied-filter ul {
    grid-template-columns: repeat(2, auto);
  }  
}