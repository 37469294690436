.hero {
  height: 100vh;
  background-color: #001C38;
  grid-template-columns: 100%;
  padding: 0;
  overflow: hidden;
}

.hero .content {
  position: relative;
  z-index: 3;
  justify-self: center;
  align-self: center;
}

.hero .content h1 {
  font-size: 30px;
}

.hero .content p {
  font-size: 16px;
}

.hero .content h1, 
.hero .content p {
  color: white
}

.overlay {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0d2849+0,193c6a+100&1+0,0.7+100 */
  background: rgba(0, 0, 0, 0.56);
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-content: center;
}

.hero .background {
  position: absolute;
  z-index: 1;
  overflow: hidden;
}

.hero .background img {
  position: relative;
  /* top: -240px; */
}

/* .home {

} */